import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Projects from "./Pages/Projects";
import ContactMe from "./Pages/ContactMe";
import NotFound from "./Pages/NotFound";
import Menu from "./Component/Menu";
import CustomCursor from "./Component/CustomCursor";
import CustomCircles from './Component/CustomCircles';
import SecretButton from './Component/SecretButton';

export default function App() {
  return (
    <Router>
      <CustomCursor />
      <CustomCircles />
      <SecretButton />
      <div>
        <Menu />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/contactme" element={<ContactMe />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
}
