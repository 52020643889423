import React, { useState } from "react";
import Footer from "../Component/Footer";
import SelectedUser from "../Component/SelectedUser";
import ProjectsAnimation from "../Component/SVG/ProjectsAnimation";
import cars from "../img/cars-project.png";
import shop from "../img/online-shop.png";
import atremote from "../img/smart-IR.jpg";
import biology from "../img/biology.jpg";
import dornika from "../img/dornika.jpg";
import fleet from "../img/fleet.jpg";
import dashboard from "../img/dashboard.jpg";
import Edge from "../img/Edgecomenergy.png";
import Consmartx from "../img/Consmartx.jpg";
import Coffee from "../img/coffee.jpg";
import BaseBeauty from "../img/basebeauty.jpg";
import SavoRAyRecipes from "../img/SavoRAyRecipes.png";

export default function Projects() {
  const [project, setProject] = useState([
    {
      id: -3,
      img: SavoRAyRecipes,
      title: "SavoRAy Recipes",
      link: "https://github.com/azinmbd/SavoRAy_Recipes",
      p: "SavoRAy Recipes is an AI-powered recipe-sharing platform designed to transform cooking habits. This project leverages AI to provide personalized recipe recommendations based on the ingredients users have on hand, helping reduce food waste, save money, and promote healthier lifestyles. The platform was developed using React.js, MongoDB, Node.js, Material-UI, Redux, SASS, RESTful APIs, and Swagger.",
    },
    {
      id: -2,
      img: BaseBeauty,
      title: "BaseBeauty",
      link: "https://github.com/azinmbd/BaseBeautyProject",
      p: "BaseBeauty is a dedicated platform for beauty bloggers, combining both UI design and full-stack development. The UI was meticulously crafted in Figma, while the development leveraged React.js, PostgreSQL, Spring Boot, Thymeleaf, Stripe, Redux, SASS, and Material-UI to create a seamless user experience.",
    },
    {
      id: -1,
      img: Coffee,
      title: "Pepua Coffee",
      link: "https://github.com/azinmbd/Papua_coffee_Code",
      p: "Pepua Coffee is an eCommerce website designed and developed for a coffee shop. The design process was conducted in Sketch, and the development was executed using React.js, Redux, Node.js, Express.js, SASS, and Material-UI, resulting in a user-friendly online shopping experience.",
    },
    {
      id: 0,
      img: Consmartx,
      title: "ConsmartX",
      link: "https://www.consmartx.com/",
      p: "ConsmartX is an interior design website developed for a Canadian company. The entire project, from design to deployment, was executed in Webflow, providing a visually appealing and highly functional website.",
    },
    {
      id: 1,
      img: Edge,
      title: "Edgecom Energy",
      link: "https://www.edgecomenergy.ca/",
      p: "At Edgecom Energy, I contributed to designing and developing energy management dashboards. I also enhanced the main website’s design and functionality, driving improved user engagement and visual appeal.",
    },
    {
      id: 2,
      img: cars,
      title: "Cars Marketplace",
      p: "Cars Marketplace is an online platform for buying and selling new and used cars. The user interface was designed using Adobe XD, focusing on an intuitive and user-friendly experience.",
    },
    {
      id: 3,
      img: dashboard,
      title: "Lora Dashboard",
      link: "https://dash.lora.mojito.atrovan.com/",
      p: "Lora Dashboard is an online platform for home electricity management and payments. The project was developed using React.js, Redux, RESTful APIs, and SASS, with the design created in Adobe XD to ensure a modern and efficient user experience.",
    },
    {
      id: 4,
      img: atremote,
      title: "Smart IR Remote",
      link: "https://cafebazaar.ir/app/com.atrovan.atremote/?l=en",
      p: "Smart IR Remote is a smart home application designed to control IR-enabled devices. The app's UI was crafted in Sketch, focusing on a user-centric design that enhances the smart home experience.",
    },
    {
      id: 5,
      img: biology,
      title: "Online Biology Courses",
      link: "http://darwino.ir",
      p: "This project is a special design for online biology learning courses. The design was executed using Sketch and Invision, while HTML, CSS, and JavaScript were employed for development, offering an engaging educational platform. (Note: This information pertains to an older version of the app and website.)",
    },
    {
      id: 6,
      img: dornika,
      title: "Medical Services Platform",
      p: "Dornika is a platform designed for medical services, enabling online checkups and consultations. The project was designed in Adobe XD, with development carried out using HTML, CSS, and JavaScript to deliver a reliable and efficient medical service experience.",
    },
    {
      id: 7,
      img: fleet,
      title: "Fleet Management System",
      link: "https://www.navgoon.com/",
      p: "This Fleet Management System provides real-time tracking and management of vehicles. Designed in Sketch and developed using React.js, the platform offers users comprehensive insights into fleet operations.",
    },
    {
      id: 8,
      img: shop,
      title: "Online Shop",
      p: "This Online Shop platform was developed for secondhand goods. The project’s design was created in Sketch, with development carried out using HTML, CSS, and JavaScript, resulting in a seamless online shopping experience.",
    },
  ]);

  const [selectedItem, setSelectedItem] = useState({
    selectedItem: null,
  });

  const closePop = () => {
    setSelectedItem({ selectedItem: null });
  };

  const getItem = (item) => {
    setSelectedItem({ selectedItem: item });
  };

  const renderList = () => {
    return project.map((item) => {
      return (
        <div
          className="col-lg-4 mt-4 hover-target"
          key={item.id}
          onClick={() => getItem(item)}
        >
          <div className="card works projects hover-target">
            <img
              src={item.img}
              className="card-img-top hover-target"
              alt={item.title}
            />
            <div className="card-body mt-3">
              <h4 className="card-title">{item.title}</h4>
              <p className="card-text">{item.p}</p>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <React.Fragment>
      <SelectedUser closePop={closePop} user={selectedItem.selectedItem} />
      <section className="d-flex align-items-center pt-5 first-section2">
        <div className="container pt-5">
          <div className="row pt-5">
            <div className="col-lg-8 col-sm-12">
              <h1>Recent Projects</h1>
              <p>
                Here are some of my favorite and most popular projects, with
                details about the development and design process.
              </p>
            </div>
            <div className="col-lg-4 d-flex justify-content-end col-sm-12">
              <ProjectsAnimation />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row pt-5">
            <div className="row mt-4">{renderList()}</div>
          </div>
        </div>
      </section>
      <Footer />
    </React.Fragment>
  );
}
