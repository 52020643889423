import React from "react";
export default function CodePen () {

    return (
      <a href="https://codepen.io/azin-mobedmehdiabadi" className="hover-target">
        <svg
          version="1.1"
          id="Bold"
          xmlns="http://www.w3.org/2000/svg"
          xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 512 512"
          //   style={{enable-background:new 0 0 512 512;}}
          space="preserve"
          fill="#525469"
        >
          <g>
            <polygon
              className="st0"
              points="241.4,196.1 241.4,128.1 127.2,204.3 178.1,238.3 	"
            />
            <polygon
              className="st0"
              points="127.2,307.7 241.4,383.8 241.4,315.8 178.1,273.6 	"
            />
            <polygon
              className="st0"
              points="115.5,231.5 115.5,231.5 115.5,280.4 152,256 	"
            />
            <polygon
              className="st0"
              points="384.7,204.3 270.5,128.1 270.5,196.1 333.8,238.3 	"
            />
            <path
              className="st0"
              d="M254.9,4.2C116.6,4.2,4.4,116.3,4.4,254.6s112.1,250.4,250.4,250.4S505.3,393,505.3,254.6S393.2,4.2,254.9,4.2
  z M425.7,307.6c0,4.7-2.5,9.5-6.4,12.1L264.1,423.2c-5.3,3.3-11,3.3-16.3,0L92.7,319.8c-4-2.6-6.4-7.4-6.4-12.1V204.3
  c0-4.7,2.5-9.5,6.4-12.1L247.8,88.7c5.3-3.3,11-3.3,16.3,0l155.1,103.4c4,2.6,6.4,7.4,6.4,12.1V307.6z"
            />
            <polygon
              className="st0"
              points="256,221.5 204.5,255.9 256,290.4 307.5,255.9 	"
            />
            <polygon
              className="st0"
              points="396.5,280.4 396.5,231.5 359.9,255.9 	"
            />
            <polygon
              className="st0"
              points="270.5,315.8 270.5,383.8 384.7,307.7 333.8,273.6 	"
            />
          </g>
        </svg>
      </a>
    );
  }

