import React from "react";
export default function Dribble (){

    return (
      <a href="https://dribbble.com/azin-mobedmehdiabadi" className="hover-target">
        <svg
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 97.8 97.8"
          // style="enable-background:new 0 0 97.8 97.8;"
          space="preserve"
          fill="#525469"
        >
          <g>
            <g>
              <path
                className="st0"
                d="M46.9,38.8c-4.9-8.7-10.1-16-11.2-17.4C27.3,25.4,21,33.1,19,42.5c0.1,0,0.2,0,0.3,0
                    C22.1,42.5,33.8,42.2,46.9,38.8z"
              />
              <path
                className="st0"
                d="M69,26c-5.4-4.7-12.4-7.6-20.1-7.6c-2.4,0-4.7,0.3-6.9,0.8c1.2,1.7,6.5,8.9,11.2,17.6
                    C63.5,32.9,68.2,27.1,69,26z"
              />
              <path
                className="st0"
                d="M57.7,46c0.2,0.5,0.4,1,0.6,1.5c2.4-0.3,4.7-0.4,7-0.4c7,0,12.8,1.1,14.1,1.4c-0.1-7-2.6-13.5-6.8-18.6
                    c-1.1,1.4-6.2,7.5-16.9,11.9C56.4,43.1,57.1,44.5,57.7,46z"
              />
              <path
                className="st0"
                d="M51,49c0.3-0.1,0.7-0.2,1-0.3c-0.7-1.6-1.5-3.3-2.3-4.9c-13.6,4-26.7,4.3-30.5,4.3c-0.4,0-0.6,0-0.8,0
                    c0,0.3,0,0.5,0,0.8c0,7.8,2.9,14.8,7.7,20.2C27.3,67,35.7,54,51,49z"
              />
              <path
                className="st0"
                d="M48.9,0C21.9,0,0,21.9,0,48.9s21.9,48.9,48.9,48.9s48.9-21.9,48.9-48.9S75.9,0,48.9,0z M48.9,85
                    c-19.9,0-36.2-16.2-36.2-36.1c0-19.9,16.2-36.1,36.2-36.1c19.9,0,36.2,16.2,36.2,36.1C85.1,68.8,68.8,85,48.9,85z"
              />
              <path
                className="st0"
                d="M60.3,52.6c3.8,10.5,5.4,19.1,5.9,21.4c6.7-4.6,11.5-11.8,12.8-20.1c-1.2-0.4-6.2-1.8-12.5-1.8
                    C64.5,52.2,62.5,52.3,60.3,52.6z"
              />
              <path
                className="st0"
                d="M54.2,54.1c-16.7,5.8-23.1,17.2-24,18.9c5.2,4,11.6,6.4,18.6,6.4c4.2,0,8.2-0.8,11.8-2.4
                    C60.2,74.2,58.4,65.1,54.2,54.1C54.2,54.1,54.2,54.1,54.2,54.1z"
              />
            </g>
          </g>
        </svg>
      </a>
    );
  }

