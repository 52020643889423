import React from "react";
export default function Instagram () {

    return (
      <a href="https://www.instagram.com/azin_ui/" className="hover-target">
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 512 512"
          // style="enable-background:new 0 0 512 512;"
          space="preserve"
          fill="#525469"
        >
          <path
            className="st0"
            d="M305,256c0,27.1-21.9,49-49,49s-49-21.9-49-49s21.9-49,49-49S305,228.9,305,256z"
          />
          <path
            className="st0"
            d="M370.6,169.3c-2.4-6.4-6.1-12.2-11-16.9c-4.7-4.9-10.5-8.6-16.9-11c-5.2-2-13-4.4-27.3-5.1
	c-15.5-0.7-20.2-0.9-59.4-0.9c-39.3,0-43.9,0.1-59.4,0.9c-14.3,0.7-22.1,3.1-27.3,5.1c-6.4,2.4-12.2,6.1-16.9,11
	c-4.9,4.7-8.6,10.5-11,16.9c-2,5.2-4.4,13-5.1,27.3c-0.7,15.5-0.9,20.1-0.9,59.4c0,39.3,0.2,43.9,0.9,59.4c0.7,14.3,3,22.1,5.1,27.3
	c2.4,6.4,6.1,12.2,11,16.9c4.7,4.9,10.5,8.6,16.9,11c5.2,2,13,4.4,27.3,5.1c15.5,0.7,20.1,0.9,59.4,0.9c39.3,0,43.9-0.1,59.4-0.9
	c14.3-0.7,22.1-3,27.3-5.1c12.8-4.9,23-15.1,27.9-27.9c2-5.2,4.4-13,5.1-27.3c0.7-15.5,0.9-20.2,0.9-59.4c0-39.3-0.1-43.9-0.9-59.4
	C375,182.3,372.6,174.5,370.6,169.3z M256,331.5c-41.7,0-75.5-33.8-75.5-75.5s33.8-75.5,75.5-75.5c41.7,0,75.5,33.8,75.5,75.5
	S297.7,331.5,256,331.5z M334.5,195.2c-9.7,0-17.6-7.9-17.6-17.6s7.9-17.6,17.6-17.6s17.6,7.9,17.6,17.6
	C352.1,187.3,344.2,195.2,334.5,195.2z"
          />
          <path
            className="st0"
            d="M256,0C114.6,0,0,114.6,0,256s114.6,256,256,256s256-114.6,256-256S397.4,0,256,0z M402.1,316.6
	c-0.7,15.6-3.2,26.3-6.8,35.7c-7.6,19.7-23.2,35.4-43,43c-9.3,3.6-20,6.1-35.7,6.8c-15.7,0.7-20.7,0.9-60.6,0.9
	c-39.9,0-44.9-0.2-60.6-0.9c-15.6-0.7-26.3-3.2-35.7-6.8c-9.8-3.7-18.7-9.5-26-17c-7.5-7.3-13.3-16.2-17-26
	c-3.6-9.3-6.1-20-6.8-35.7c-0.7-15.7-0.9-20.7-0.9-60.6s0.2-44.9,0.9-60.6c0.7-15.6,3.2-26.3,6.8-35.7c3.7-9.8,9.5-18.7,17-26
	c7.3-7.5,16.2-13.3,26-17c9.4-3.6,20-6.1,35.7-6.8c15.7-0.7,20.7-0.9,60.6-0.9s44.9,0.2,60.6,0.9c15.6,0.7,26.3,3.2,35.7,6.8
	c9.8,3.7,18.7,9.5,26,17c7.5,7.3,13.3,16.2,17,26c3.6,9.4,6.1,20,6.8,35.7c0.7,15.7,0.9,20.7,0.9,60.6S402.8,300.9,402.1,316.6z"
          />
        </svg>
      </a>
    );
  }

