import React from "react";
import { useNavigate } from "react-router-dom";
import error from "../img/404.svg";

export default function NotFound() {
  const navigate = useNavigate();

  return (
    <section className="notfound-bg">
      <div className="container d-flex flex-column align-items-center justify-content-center name">
        <img src={error} width="50%" alt="404 Error - Page Not Found" />
        <span className="notFound-font mt-4 text-center">
          Sorry, the page you are looking for could not be found!
        </span>
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
            navigate("/");
          }}
          className="filledBtn hover-target mt-4"
        >
          Go to Home Page
        </a>
      </div>
    </section>
  );
}
