import React from "react";
import Cards from "./Cards";
import AOS from "aos";

export default function Knoledge () {
    AOS.init();
    AOS.init({disable: 'mobile'});
    return (
      <section className="pt-5 pb-5">
        <div className="container mt-5 mb-5">
          <div className="services">
            <h3 className="text-center">What I Do</h3>
            <span className="line"></span>
          </div>
          <div className="row ">
            <Cards />
          </div>
        </div>
      </section>
    );
  }

