import React  from "react";
export default function FilledBtn (){

    return (
      <a href="mailto:azin.moubed@gmail.com"   className="filledBtn hover-target">
        Contact Me
      </a>
    );
  }

