import React from "react";
import LinerBtn from "../Component/Btns/linier";
import FilledBtn from "../Component/Btns/Filled";
import ME from "../img/Profile.png";

export default function FirstSection() {
  return (
    <section className="landing-bg pt-5 pb-5 d-flex align-items-center first-section">
      <div className="container pt-5 pb-5">
        <div className="row ">
          <div className="col-lg-6 name ">
            <div
              data-aos="fade-right"
              data-aos-offset="300"
              data-aos-duration="1000"
              data-aos-easing="ease-in-sine"
            >
              <h1>
                Azin
                <br />
                Mobedmehdiabadi
              </h1>
              <span className="line"></span>
              <h2 className="mt-4">Front-end developer & UI Designer</h2>
              <div className="d-flex ">
                <FilledBtn />
                <LinerBtn download="Download CV" />
              </div>
            </div>
          </div>
          <div
            className="col-lg-6"
            data-aos="fade-left"
            data-aos-offset="300"
            data-aos-duration="1000"
            data-aos-easing="ease-in-sine"
          >
            <img src={ME} className="myPhoto" alt="" width="100%" />
          </div>
        </div>
      </div>
    </section>
  );
}
