import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import "../CSS/cursor.css";

const CustomCursor = () => {
  const cursorRef = useRef(null);
  const followerRef = useRef(null);
  const location = useLocation();

  const attachEventListeners = () => {
    const elements = document.querySelectorAll("a, button, input, .hover-target");
    const addHoverClass = () => followerRef.current.classList.add("is-hovering");
    const removeHoverClass = () => followerRef.current.classList.remove("is-hovering");

    elements.forEach((el) => {
      el.addEventListener("mouseenter", addHoverClass);
      el.addEventListener("mouseleave", removeHoverClass);
    });

    return () => {
      elements.forEach((el) => {
        el.removeEventListener("mouseenter", addHoverClass);
        el.removeEventListener("mouseleave", removeHoverClass);
      });
    };
  };

  useEffect(() => {
    let mouseX = 0, mouseY = 0;
    let followerX = 0, followerY = 0;

    const moveCursor = (e) => {
      mouseX = e.clientX;
      mouseY = e.clientY;
      cursorRef.current.style.left = `${mouseX}px`;
      cursorRef.current.style.top = `${mouseY}px`;
    };

    const animateFollower = () => {
      followerX += (mouseX - followerX) * 0.1;
      followerY += (mouseY - followerY) * 0.1;
      followerRef.current.style.left = `${followerX}px`;
      followerRef.current.style.top = `${followerY}px`;

      requestAnimationFrame(animateFollower);
    };

    document.addEventListener("mousemove", moveCursor);
    requestAnimationFrame(animateFollower);

    // Initial attachment of event listeners
    const cleanupEventListeners = attachEventListeners();

    return () => {
      document.removeEventListener("mousemove", moveCursor);
      cleanupEventListeners();
    };
  }, []);

  useEffect(() => {
    // Re-attach event listeners when location changes
    const cleanupEventListeners = attachEventListeners();
    return () => cleanupEventListeners();
  }, [location]);

  return (
    <>
      <div className="custom-cursor" ref={cursorRef}></div>
      <div className="cursor-follower" ref={followerRef}></div>
    </>
  );
};

export default CustomCursor;
