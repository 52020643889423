import React from "react";
import close from "../img/x-square.svg";
export default function SelectedUser(props) {
  const { user } = props;
  if (props.user !== null) {
    return (
      <div className="modal-bg" onClick={() => props.closePop()}>
        <div className=" modal-box">
          <img
            src={close}
            className="close-modal hover-target"
            alt=""
            onClick={() => props.closePop()}
          />
          <div className="d-flex flex-column p-3">
            <img src={user.img} className="card-img-top modal-img" alt="..." />
            <div className=" card-body mt-3">
              <h4 className="card-title">{user.title}</h4>
              <p className="card-text">{user.p}</p>
              {user.link ? (
                <a href={user.link} target="_blank" className="hover-target link">
                  visit page
                </a>
              ) : (
                <span></span>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <span></span>;
  }
}
