import React, { useState } from "react";
import experience from "../img/experience.svg";
export default function Experience() {
  const [state, setState] = useState({
    h1: "My Education",
    p: "I graduated from Douglas College, one of the top-ranked colleges in Canada. Pursuing my education in a field closely aligned with my professional experiences in UI/UX design and development has allowed me to keep my knowledge current and relevant.",
    info: [
      {
        id: 1,
        h6: "Front-End Development",
        p: "I graduated from Douglas College with a Post-Baccalaureate Diploma in Computer and Information Systems, where I honed my skills in full-stack development. Through self-study, I mastered HTML, CSS, and JavaScript. Additionally, I hold a Front-End Development certificate from Meta, and I am proficient in using React, Redux, and other modern web technologies to build responsive and user-centric applications.",
      },
      {
        id: 2,
        h6: "UI/UX Design",
        p: "I earned a UI/UX design certificate from the Arjang Institute and was honored to receive the CIW certification with top marks in this course.",
      },
    ],
  });

  const educationList = () => {
    return state.info.map((item) => {
      return (
        <div
          key={item.id}
          data-aos="flip-right"
          data-aos-offset="300"
          data-aos-duration="1000"
          data-aos-easing="ease-in-sine"
        >
          <h5>{item.h6}</h5>
          <span className="line"></span>
          <p>{item.p}</p>
        </div>
      );
    });
  };
  return (
    <section className="p-5 light">
      <div className="container pt-5 pb-5 mt-5 mb-5">
        <div className="row">
          <div className="col-lg-12 text-center services mb-5">
            <h3>Education & Experience</h3>

            <span className="line"></span>
          </div>

          <div className="row">
            <div className="col-lg-5 col-sm-12 d-flex align-items-center flex-column">
              <img src={experience} alt="" width="100%" className="mb-3" />
              <div>
                <h4>{state.h1}</h4>
                <p>{state.p}</p>
              </div>
            </div>
            <div className="col-lg-2  shape">
              <div></div>
              <span></span>
              <span></span>
              <div></div>
            </div>
            <div className="col-lg-5 col-sm-12 experience d-flex flex-column justify-content-center">
              {educationList()}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
