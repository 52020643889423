import React from "react";
import CountUp from "react-countup";
import about from "../img/about.svg";

export default function About() {
  return (
    <section className="light pt-5 pb-5">
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-lg-6">
            <div className="aboutMe p-4">
              <img src={about} width="100%" alt="4 years of experience" />
            </div>
          </div>
          <div className="col-lg-6 story p-4">
            <h3>About Me</h3>
            <span></span>
            <p className="mt-3">
              I am an experienced Interface Specialist, skilled in designing
              user interfaces, wireframes, flowcharts, and applying UX
              techniques across various applications and websites. Additionally,
              I possess strong front-end web development skills and coding
              expertise. As a lifelong learner, I am continually seeking
              opportunities to enhance my knowledge and capabilities.
              <br />
              Although my initial field of study did not fulfill my aspirations,
              I transitioned into web design and development. I have since
              graduated with a degree in computer and information systems, which
              has equipped me with the knowledge and skills to stay at the
              forefront of my profession.
            </p>
            <div className="d-flex justify-content-between Projects mt-5 flex-wrap">
              <div>
                <h6>UI/UX Projects:</h6>
                <CountUp start={0} end={47} delay={2} />
              </div>
              <div>
                <h6>Front-End Projects:</h6>
                <CountUp start={0} end={15} delay={2} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
