import React, { useState } from "react";
import letter from "../img/letter.svg";
import mail from "../img/mail.svg";
import type from "../img/type.svg";
import user from "../img/user.svg";
import Dribble from "../Component/SVG/dribble";
import CodePen from "../Component/SVG/codepen";
import GitHub from "../Component/SVG/github";
import Instagram from "../Component/SVG/instagram";
import Linkdin from "../Component/SVG/linkdin";
import sentIcon from "../img/message.svg"
import LetterAnimation from "../Component/SVG/LetterAnimation"

export default function ContactMe () {
const [state, setState] = useState({
  formClassName:"d-block",
  messageClassName:"d-none"
})
  const handleSubmit=()=>{

      setState({ formClassName: "d-none",
    messageClassName:"d-block"  });
  }
  const getFormClass=()=>{
    return   state.formClassName
  }
  const getMessageClass=()=>{
    return   state.messageClassName
  }
    return (
      <section className="footer contactMe first-section">
        <div className="container">
          <div className="row">
            <div className={`col-lg-6 footer-info form-box ${  getFormClass()}`}>
              <h3>Contact Me</h3>
              <p>
                You can contact me for any work request or more information
                .
              </p>
              <br />
              <form action="mailto:azin.moubed@gmail.com" method="post" encType="text/plain" onSubmit={  handleSubmit} >
                <div className="form-items">
                  <img src={user} alt="" />
                  <input type="text" name="name" required="required" placeholder="Your Name ..." />
                  <br />
                </div>
                <div className="form-items">
                  <br />

                  <img src={mail} alt="" />
                  <input
                  required="required"
                    type="email"
                    name="mail"
                    placeholder="Your Email ..."
                  />
                  <br />
                </div>
                <div className="form-items">
                  <img src={type} alt="" />
                  <textarea
                  required="required"
                    type="text"
                    name="comment"
                    size="150"
                    placeholder="Message ..."
                  />
                  <br />
                </div>
                <input type="submit" value="Send" className="filled hover-target"/>
              </form>

            </div>
              <div  className={`col-lg-6 text-center  ${  getMessageClass()}`}>
                <img src={sentIcon} width="50%" alt=""/>
              <h3>Thanks for your message</h3>

              </div>
            <div className="col-lg-6 footer-info contactMe-social">
              <LetterAnimation />
              <span>Social</span>
              <div className="d-flex justify-content-between social">
                <Instagram />
                <Linkdin />
                <Dribble />
                <CodePen />
                <GitHub />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}

