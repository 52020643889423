import React  from "react";
export default function GitHub () {

    return (
      <a href="https://github.com/azinmbd" className="hover-target">
          <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
        // style="enable-background:new 0 0 512 512;"
        space="preserve"
        fill="#525469"
      >
        <path
          className="st0"
          d="M256,10.7C114.6,10.7,0,123.3,0,262.2c0,111.2,73.3,205.4,175,238.7c12.8,2.4,17.5-5.4,17.5-12.1
            c0-6-0.2-21.8-0.3-42.8C121,461.2,106,412.3,106,412.3c-11.6-29-28.5-36.8-28.5-36.8c-23.2-15.6,1.8-15.3,1.8-15.3
            c25.7,1.7,39.2,25.9,39.2,25.9c22.8,38.5,59.9,27.3,74.6,20.9c2.3-16.3,8.9-27.3,16.2-33.6c-56.9-6.3-116.6-27.9-116.6-124.3
            c0-27.5,9.9-49.9,26.3-67.5c-2.9-6.4-11.5-31.9,2.2-66.6c0,0,21.4-6.7,70.4,25.8c20.5-5.6,42.2-8.4,64-8.5c21.8,0.1,43.5,2.9,64,8.5
            c48.6-32.5,70.1-25.8,70.1-25.8c13.8,34.6,5.1,60.2,2.6,66.6c16.3,17.6,26.2,40,26.2,67.5c0,96.6-59.8,117.9-116.8,124.1
            c9,7.6,17.3,23,17.3,46.5c0,33.7-0.3,60.7-0.3,68.9c0,6.6,4.5,14.5,17.6,11.9C438.7,467.6,512,373.2,512,262.2
            C512,123.3,397.4,10.7,256,10.7z"
        />
      </svg>
      </a>
    );
  }

