import React, { useState } from "react";
import Magic from "./SVG/Magic";
import "../CSS/cursor.css";
import "@fortawesome/fontawesome-free/css/all.min.css"; // Ensure this import is here

const SecretButton = () => {
  const [showModal, setShowModal] = useState(false);

  const handleSecretButtonClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div
        className="hover-target"
        id="secret-button"
        onClick={handleSecretButtonClick}
      >
        <Magic />
      </div>

      {showModal && (
        <div id="fun-fact-modal">
          <div id="fun-fact-content">
            <i
              id="close-fun-fact"
              className="fas fa-times hover-target"
              onClick={handleCloseModal}
            ></i>
            <h2
              id="fun-fact-title"
              style={{
                color: "#a291fb",
                fontFamily: "Comic Sans MS",
                textAlign: "center",
              }}
            >
              🎉 You Found the Secret! 🎉
            </h2>
            <p id="fun-fact-text">
              Did you know? In addition to my skills in web development, I have
              a passion for cake and cookie decoration! 🎂🍪
            </p>
            <p id="fun-fact-text">
              I create customized event cakes and intricate royal icing cookies.
            </p>
            <div style={{ textAlign: "center", marginTop: "10px" }}>
              <a
                id="filledbtn"
                href="https://www.instagram.com/cookie_art_love/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Check Out My Creations!
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SecretButton;
