import React from "react";
import ProgressBar from "react-animated-progress-bar";

export default function Skills() {

    return (
      <div>
        <div className="progress-box">
          <h6>PHP</h6>
          <ProgressBar
            width="90%"
            height="10px"
            rect
            fontColor="gray"
            percentage="75"
            rectPadding="1px"
            rectBorderRadius="20px"
            trackPathColor="transparent"
            bgColor="#ffffff"
            trackBorderColor="grey"
          />
        </div>

        <div className="progress-box">
          <h6>Java/ Android Studio</h6>
          <ProgressBar
            width="90%"
            height="10px"
            rect
            fontColor="gray"
            percentage="60"
            rectPadding="1px"
            rectBorderRadius="20px"
            trackPathColor="transparent"
            bgColor="#ffffff"
            trackBorderColor="grey"
          />
        </div>
        <div className="progress-box">
          <h6>Illustrator/ Photoshop</h6>
          <ProgressBar
            width="90%"
            height="10px"
            rect
            fontColor="gray"
            percentage="90"
            rectPadding="1px"
            rectBorderRadius="20px"
            trackPathColor="transparent"
            bgColor="#ffffff"
            trackBorderColor="grey"
          />
        </div>
        <div className="progress-box">
          <h6>Sketch/ XD/ Figma</h6>
          <ProgressBar
            width="90%"
            height="10px"
            rect
            fontColor="gray"
            percentage="98"
            rectPadding="1px"
            rectBorderRadius="20px"
            trackPathColor="transparent"
            bgColor="#ffffff"
            trackBorderColor="grey"
          />
        </div>
        <div className="progress-box">
          <h6>UI/UX design</h6>
          <ProgressBar
            width="90%"
            height="10px"
            rect
            fontColor="gray"
            percentage="95"
            rectPadding="1px"
            rectBorderRadius="20px"
            trackPathColor="transparent"
            bgColor="#ffffff"
            trackBorderColor="grey"
          />
        </div>
      </div>
    );
  }


