import React from "react";
import ProgressBar from "react-animated-progress-bar";

export default function Skills () {

    return (
      <div>
        <div className="progress-box">
          <h6>HTML / CSS</h6>
          <ProgressBar
            width="90%"
            height="10px"
            rect
            fontColor="gray"
            percentage="99"
            rectPadding="1px"
            rectBorderRadius="20px"
            trackPathColor="transparent"
            bgColor="#ffffff"
            trackBorderColor="grey"
          />
        </div>

        <div className="progress-box">
          <h6>Javascript / JQuery</h6>
          <ProgressBar
            width="90%"
            height="10px"
            rect
            fontColor="gray"
            percentage="90"
            rectPadding="1px"
            rectBorderRadius="20px"
            trackPathColor="transparent"
            bgColor="#ffffff"
            trackBorderColor="grey"
          />
        </div>
        <div className="progress-box">
          <h6>Sass</h6>
          <ProgressBar
            width="90%"
            height="10px"
            rect
            fontColor="gray"
            percentage="90"
            rectPadding="1px"
            rectBorderRadius="20px"
            trackPathColor="transparent"
            bgColor="#ffffff"
            trackBorderColor="grey"
          />
        </div>
        <div className="progress-box">
          <h6>React Js</h6>
          <ProgressBar
            width="90%"
            height="10px"
            rect
            fontColor="gray"
            percentage="85"
            rectPadding="1px"
            rectBorderRadius="20px"
            trackPathColor="transparent"
            bgColor="#ffffff"
            trackBorderColor="grey"
          />
        </div>
        <div className="progress-box">
          <h6>Redux</h6>
          <ProgressBar
            width="90%"
            height="10px"
            rect
            fontColor="gray"
            percentage="75"
            rectPadding="1px"
            rectBorderRadius="20px"
            trackPathColor="transparent"
            bgColor="#ffffff"
            trackBorderColor="grey"
          />
        </div>
      </div>
    );
  }

