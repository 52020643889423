import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import logo from "../img/logo.svg";

export default function Menu() {
  const openNav = () => {
    document.getElementById("Sidenav").style.width = "100%";
  };

  const closeNav = () => {
    document.getElementById("Sidenav").style.width = "0";
  };

  return (
    <header>
      <nav className="navbar-expand-lg navbar navbar-light bg-light d-block-lg ">
      <div className="container">
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <a className=" nav-item navbar-brand p-2 " href="#">
            <img src={logo} width="45" height="45" alt="" />
          </a>
          <div className="d-flex m-auto">
                      <NavLink
            className="nav-item nav-link "
            exact
            to="/"
            style={({ isActive }) =>
              isActive
                ? { color: "#a291fb", boxShadow: "0 2px 0 #a291fb" }
                : undefined
            }
          >
            Home
          </NavLink>
          <NavLink
            className="nav-item nav-link "
            to="/projects"
            style={({ isActive }) =>
              isActive
                ? { color: "#a291fb", boxShadow: "0 2px 0 #a291fb" }
                : undefined
            }
          >
            Projects
          </NavLink>
          <NavLink
            className="nav-item nav-link "
            to="/contactme"
            style={({ isActive }) =>
              isActive
                ? { color: "#a291fb", boxShadow: "0 2px 0 #a291fb" }
                : undefined
            }
          >
            Contact Me
          </NavLink>
          </div>

        </div>
      </div>
      </nav>

      <nav className="mobile-menu d-flex-sm ">
        <div id="Sidenav" className="sidenav">
          <a href="#" className="closebtn" onClick={() => closeNav()}>
            &times;
          </a>
          <a className=" nav-item navbar-brand p-2" href="#">
            <img src={logo} width="45" height="45" alt="" />
          </a>
          <NavLink
            className="nav-item nav-link "
            onClick={() => closeNav()}
            exact
            to="/"
            style={({ isActive }) =>
              isActive
                ? { color: "#a291fb", boxShadow: "0 2px 0 #a291fb" }
                : undefined
            }
          >
            Home
          </NavLink>
          <NavLink
            className="nav-item nav-link "
            onClick={() => closeNav()}
            to="/projects"
            style={({ isActive }) =>
              isActive
                ? { color: "#a291fb", boxShadow: "0 2px 0 #a291fb" }
                : undefined
            }
          >
            Projects
          </NavLink>
          <NavLink
            className="nav-item nav-link "
            onClick={closeNav}
            to="/contactme"
            style={({ isActive }) =>
              isActive
                ? { color: "#a291fb", boxShadow: "0 2px 0 #a291fb" }
                : undefined
            }
          >
            Contact Me
          </NavLink>
        </div>

        <span onClick={openNav}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="35"
            height="35"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#a291fb"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <line x1="3" y1="12" x2="21" y2="12"></line>
            <line x1="3" y1="6" x2="21" y2="6"></line>
            <line x1="3" y1="18" x2="21" y2="18"></line>
          </svg>
        </span>
      </nav>
    </header>
  );
}
