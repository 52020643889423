import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import '../CSS/cursor.css';

const generateRandomValue = (min, max) => Math.random() * (max - min) + min;
const randomColor = () => Math.random() < 0.5 ? '#ffffff' : '#17113549';

const CustomCircles = () => {
  const location = useLocation();

  useEffect(() => {
    const section = document.querySelector('.first-section, .first-section2');
    if (!section) return;

    const numberOfCircles = 15; // Only 10 circles

    for (let i = 0; i < numberOfCircles; i++) {
      const circle = document.createElement('div');
      circle.classList.add('circle');

      const size = generateRandomValue(5, 10); // Random size between 5 and 10px
      const x = generateRandomValue(0, section.clientWidth);
      const y = generateRandomValue(0, section.clientHeight);
      const duration = generateRandomValue(10, 30); // Random duration between 10 and 30s

      const moveX = generateRandomValue(-section.clientWidth, section.clientWidth);
      const moveY = generateRandomValue(-section.clientHeight, section.clientHeight);
      const color = randomColor();

      circle.style.width = `${size}px`;
      circle.style.height = `${size}px`;
      circle.style.top = `${y}px`;
      circle.style.left = `${x}px`;
      circle.style.backgroundColor = color; // Assign random color
      circle.style.animationDuration = `${duration}s`;
      circle.style.setProperty('--move-x', `${moveX}px`);
      circle.style.setProperty('--move-y', `${moveY}px`);

      section.appendChild(circle);
    }
  }, [location.pathname]);

  return null; // No visual output from the component itself
};

export default CustomCircles;
