import React from "react";
import FirstSection from "../Component/FirstSection";
import Knoledge from "../Component/Knoledge";
import About from "../Component/About";
import Skills from "../Component/Skills";
import Experience from "../Component/Experience";
import Footer from "../Component/Footer";
export default function Home() {
  return (
    <React.Fragment>
      <FirstSection />
      <Knoledge />
      <About />
      <Skills />
      <Experience />
      <Footer />
    </React.Fragment>
  );
}
