import React from "react";
import Dribble from "../Component/SVG/dribble";
import CodePen from "../Component/SVG/codepen";
import GitHub from "../Component/SVG/github";
import Instagram from "../Component/SVG/instagram";
import Linkdin from "../Component/SVG/linkdin";
import Animation from "./SVG/animation";

export default function Footer() {
  return (
    <footer className="pt-5 pb-5 footer">
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-lg-6 footer-info">
            <h3>Get in Touch</h3>
            <p>
              I am open to freelance opportunities and would love to hear from
              you. Please feel free to contact me for any inquiries or questions.
              I am always available via email or through the social media platforms
              listed below.
            </p>
            <br />
            <span>Email me at:</span>
            <p>Azin.moubed@gmail.com</p>
            <br />
            <span>Connect with me:</span>
            <div className="d-flex justify-content-between social">
              <Instagram />
              <Linkdin />
              <Dribble />
              <CodePen />
              <GitHub />
            </div>
          </div>
          <div className="col-lg-6 d-flex justify-content-center">
            <Animation />
          </div>
        </div>
      </div>
    </footer>
  );
}
