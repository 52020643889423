import React from "react";
import AzinMoubed from "../../files/Azin-Mobed-Resume.pdf";

export default function LinerBtn (props) {

    return (
      <a href={AzinMoubed} download className="linerBtn hover-target" >
        {  props.download}
      </a>
    );
  }

