import React from "react";
import FrontEnd from "../img/FrontEnd.svg";
import UI from "../img/UI.svg";
import UX from "../img/UX.svg";

export default function Cards() {
  return (
    <React.Fragment>
      <div
        className="col-lg-4 mb-5"
        data-aos="fade-up"
        data-aos-duration="2000"
        data-aos-delay="0"
      >
        <div className="works">
          <div className="d-flex align-items-center mb-4">
            <img
              src={FrontEnd}
              alt="Development"
              width="100px"
              className="mr-3"
            />
            <h4>Front-End Development</h4>
          </div>
          <p>
            Bringing ideas and designs to life in the browser is my passion. I
            enjoy coding from scratch using HTML, CSS, Sass, React.js, Java, and
            Redux. Staying up-to-date with the latest versions of frameworks
            like Material UI, tailwind css and other UI libraries is a priority
            for me. I frequently utilize tools such as CodePen, Github, and
            Visual Studio Code in my development process.
          </p>
        </div>
      </div>
      <div
        className="col-lg-4 mb-5"
        data-aos="fade-up"
        data-aos-duration="2000"
        data-aos-delay="300"
      >
        <div className="works">
          <div className="d-flex align-items-center mb-4">
            <img src={UI} alt="UI Design" width="100px" className="mr-3" />
            <h4>UI Design</h4>
          </div>
          <p>
            I began my career as a UI designer, a role I continue to approach
            with enthusiasm. My preferred design tools are Sketch, Adobe XD, and
            Figma. I share my designs using InVision and other UI-sharing
            platforms. My passion for creating new things extends to SVG and
            animation, where I enjoy bringing designs to life with creativity
            and precision.
          </p>
        </div>
      </div>
      <div
        className="col-lg-4 mb-5"
        data-aos="fade-up"
        data-aos-duration="2000"
        data-aos-delay="600"
      >
        <div className="works">
          <div className="d-flex align-items-center mb-4">
            <img src={UX} alt="UX Design" width="100px" className="mr-3" />
            <h4>UX Design</h4>
          </div>
          <p>
            I believe UX design is the cornerstone of any successful project.
            Effective user experience design requires a comprehensive approach,
            incorporating interaction design, prototyping, usability testing,
            wireframing, user personas, user flows, storyboarding, and visual
            design. By utilizing these techniques, I strive to create intuitive
            and engaging experiences that resonate with users and enhance
            project outcomes.
          </p>
        </div>
      </div>
    </React.Fragment>
  );
}
